import React, { useState } from "react"
import { Link, graphql } from "gatsby"

import SEO from "../components/seo"
import { format } from "date-fns"
import { useMeasure } from "react-use"
import { useSpring, animated } from "@react-spring/web"

const seasons = ["Winter", "Spring", "Summer", "Fall"]

const getKeyFromDate = date => {
  return `${seasons[Number(format(new Date(date), "q")) - 1]} ${format(
    new Date(date),
    "yyyy"
  )}`
}

const Drawer = ({ label, items }) => {
  const [isOpen, setIsOpen] = useState(true)
  const [ref, { height }] = useMeasure()
  const style = useSpring({ height: isOpen ? height : 0 })

  return (
    <div className="drawer">
      <div className="drawer-header">
        <h2>{label}</h2>
        <button onClick={() => setIsOpen(current => !current)}>
          {isOpen ? "Collapse" : "Expand"}
        </button>
      </div>
      <animated.div style={style} className="drawer-wrapper">
        <div ref={ref} className="drawer-body">
          {items.map((item, i) => (
            <Link to={"/articles/" + item.uid} className="drawer-item" key={i}>
              <img
                className="drawer-item__mobile-image"
                src={item.data.hero_image.url}
              />
              <div className="drawer-item__title">
                <p>{item.data.subtitle}</p>
              </div>
            </Link>
          ))}
        </div>
      </animated.div>
    </div>
  )
}

const ArchivesPage = ({ data }) => {
  const archives = data.allPrismicArticle.edges.reduce((acc, { node }) => {
    const key = getKeyFromDate(node.first_publication_date)
    if (key in acc === false) {
      acc[key] = []
    }
    acc[key].push(node)
    return acc
  }, {})
  //   console.log(archives)
  //   console.log(Object.keys(archives))

  return (
    <>
      <SEO title="Archives" />
      {Object.keys(archives).map((key, i) => (
        <Drawer key={i} label={key} items={archives[key]} />
      ))}
    </>
  )
}

export const query = graphql`
  {
    allPrismicArticle(sort: { fields: first_publication_date, order: DESC }) {
      edges {
        node {
          first_publication_date
          id
          data {
            author
            content {
              html
            }
            hero_image {
              alt
              url
            }
            title
            subtitle
          }
          uid
        }
      }
    }
  }
`

export default ArchivesPage
